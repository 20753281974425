<template>
<div>
  <h1 style="display: inline;">Multi Guests</h1>
  <div style="display: inline; padding-left:10px">
    <input type="checkbox" id="dm" v-model="debugMode"/>
    <label for="dm">&nbsp;Debug Mode</label>
  </div>
  <b-tabs content-class="mt-2">
    <b-tab :title="u.name" v-for="u in users" v-bind:key="u.id" @click="selectGuest(u)">
    <div class="container">
      <button class="btn btn-warning mb-2" @click="removeUser(u.id)">Remove {{ u.name }} from Multiguest</button>
      <guest :manager="manager" ref="guests" :propUid="u.id" :debugMode="debugMode"/>
    </div>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import { db } from "@/services/db";
import { getLog } from "@/services/log";
import guest from "../guest/Guest.vue";
let log = getLog('test');

export default {
  name: 'app',
  components: {
    guest
  },
  data() {
    return {
      manager: null,
      users: [],
      debugMode: true,
    }
  },
  async mounted() {
    this.$app.router = {};
    await this.$bind("manager", db.collection("LiveEvents").doc("manager"));
    if (this.$route.query.users) {
      this.users = JSON.parse(this.$route.query.users);
      log.log("users=", this.users);
    }
  },
  methods: {
    selectGuest(u) {
      document.title = "MG - " + u.name;
    },
    removeUser(uid) {
      this.users = this.users.filter(u => u.id != uid);
    }
  }
}
</script>